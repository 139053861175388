/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import HeaderStyleTen from "@/components/Header/HeaderStyle-Ten";
import MobileMenu from "@/components/Header/MobileMenu";
import Cart from "@/components/Header/Offcanvas/Cart";
import PageHead from "@/pages/Head";
import BackToTop from "@/pages/backToTop";
import { useAuthContext } from "@/context/auth/AuthContext";
import Tools from "@/components/Builder/Tools";
import { useRouter } from "next/router";
import { connectToDatabase } from "@/components/Server/Database/MongoDB";

const LoginPage = ({ info }) => {
  const brand = JSON.parse(info);
  const router = useRouter();
  const { login, loading, error, setError, user } = useAuthContext();
  const inputs = [
    {
      element: "text",
      name: "email",
      label: "Email",
      icon: "rbt-icon-top feather-mail",
      width: "col-md-12",
      required: true,
      placeholder: "Enter Username or Email",
    },
    {
      element: "password",
      name: "password",
      icon: "rbt-icon-top feather-lock",
      label: "Password",
      width: "col-md-12",
      required: true,
      placeholder: "Enter your password",
    },
    {
      element: "checkbox",
      name: "rememberMe",
      items: "remember me",
      width: "col-md-12",
    },
  ];

  const initialState = {};
  inputs.forEach((input) => {
    initialState[input.name] = "";
  });

  const [state, setState] = useState(initialState);

  const handleLogin = async (e) => {
    e.preventDefault();
    await login({ ...state, partnerId: brand._id });
  };

  const redirect = () => {
    router.push("/student/student-dashboard");
  };

  useEffect(() => {
    if (user) {
      redirect();
    }
    const getCookie = async () => {
      // Function to get a cookie value by name
      function getCookie(name) {
        let match = document.cookie.match(
          new RegExp("(^| )" + name + "=([^;]+)")
        );
        if (match) return match[2];
      }

      // Function to set a cookie
      function setCookie(name, value, days) {
        let expires = "";
        if (days) {
          const date = new Date();
          date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
          expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
      }
      const bootstrap = await import("bootstrap");

      // Show modal if the cookie is not accepted
      if (!getCookie("cookiesAccepted")) {
        const cookieModal = new bootstrap.Modal(
          document.getElementById("cookieModal")
        );
        cookieModal.show();
      }
      document
        .getElementById("acceptCookies")
        .addEventListener("click", function () {
          setCookie("cookiesAccepted", "true", 365);

          const cookieModal = bootstrap.Modal.getInstance(
            document.getElementById("cookieModal")
          );
          cookieModal.hide();

          // Ensure the backdrop is removed
          const backdrop = document.querySelector(".modal-backdrop");
          if (backdrop) {
            backdrop.remove();
          }
        });
    };
    getCookie();
  }, [user]);

  return (
    <>
      <PageHead
        title={`Login - Online Courses & Education Powered By ${brand.displayName}`}
      />
      <HeaderStyleTen brand={brand} headerType="" />
      <MobileMenu brand={brand} />
      <Cart />
      <div
        className="modal fade"
        id="cookieModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="cookieModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title theme-gradient" id="cookieModalLabel">
                Cookie Policy
              </h4>
            </div>
            <div className="modal-body">
              <p>
                We use cookies to ensure you get the best experience on our
                website. By continuing to browse the site, you agree to our use
                of cookies.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="rbt-btn btn-sm btn-primary"
                data-bs-toggle="collapse"
                id="acceptCookies"
              >
                Accept Cookies
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="rbt-elements-area bg-image rbt-section-gap">
        <div className="container">
          <div className="col-lg-8 mx-auto">
            <div className="rbt-contact-form contact-form-style-1 max-width-auto">
              <h3 className="title">Login</h3>
              <form className="max-width-auto" onSubmit={handleLogin}>
                {error && <div className="alert alert-danger">{error}</div>}
                <div className="row">
                  <Tools
                    setState={(e) => {
                      setState(e);
                      setError(null);
                    }}
                    state={state}
                    inputs={inputs}
                  />
                </div>

                <div className="form-submit-group mt-3">
                  <button
                    type="submit"
                    className={`rbt-btn btn-md btn-gradient hover-icon-reverse w-100 ${
                      loading ? "loading" : ""
                    }`}
                    disabled={loading}
                  >
                    <span className="icon-reverse-wrapper">
                      <span className="btn-text">
                        {loading ? "Logging in..." : "Log In"}
                      </span>
                      <span className="btn-icon">
                        <i className="feather-arrow-right"></i>
                      </span>
                      <span className="btn-icon">
                        <i className="feather-arrow-right"></i>
                      </span>
                    </span>
                  </button>
                  {/* <Link href="/reset-password" className="fs-4 mt-2">
                    Reset Password
                  </Link> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/*   <div className="rbt-newsletter-area bg-gradient-6 ptb--50">
        <NewsletterThree />
      </div> */}

      <BackToTop />
      {/* <FooterOne brand={brand} /> */}
    </>
  );
};

export const getServerSideProps = async (context) => {
  const domainName = context.req ? context.req.headers.host : null;

  const { db } = await connectToDatabase();

  const info = await db.collection("partners").findOne({
    key: process.env.DEFAULT || domainName,
  });

  if (!info) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      info: JSON.stringify(info),
    },
  };
};

export default LoginPage;
